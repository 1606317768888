import { ThematicCode } from 'pleinchamp-api-client';
import { ActivityCode, ContentType, FreeFieldCode, InterestCode } from 'pleinchamp-api-client/dist/src/content/content';
import { Content, SearchType } from 'pleinchamp-api-client/dist/src/content/search';
import { Geopoint } from 'pleinchamp-api-client/dist/src/custom';
import { FreeFieldApi } from '@api/business/FreeFieldContent.api';
import { filterGuides } from '@guides/business/Guides.utils';
import {
  call15dForecasts,
  call36hForecasts,
  callHourlyForecasts,
  callSummaryForecasts,
} from '@meteo/business/Meteo.api';
import { thematicInUrl } from '@search/business/search.types';
import { getCustomJournalNews, searchContent } from '@search/business/search.utils';
import { getProductType } from '@stocks/business/Stocks.api';
import { NB_HOME_EVENTS, NB_HOME_NEWS_HEADLINES, NB_LAST_GUIDES } from '@utils/api.utils';
import { Constants } from '@utils/Constants';
import { plcDayjs } from '@utils/date';
import { transformUrlParamToEnum } from '@utils/url';
import { UserProductType } from 'pleinchamp-api-client/dist/src/productTypes/productTypes';

export function fetch36hForecasts(coord?: Geopoint) {
  if (!coord) {
    return Promise.resolve(undefined);
  }
  const { latitude, longitude } = coord;
  return call36hForecasts(latitude, longitude);
}

export function fetch15dForecasts(coord?: Geopoint) {
  if (!coord) {
    return Promise.resolve(undefined);
  }
  const { latitude, longitude } = coord;
  return call15dForecasts(latitude, longitude);
}

export function fetchSummaryForecasts(coord?: Geopoint) {
  if (!coord) {
    return Promise.resolve(undefined);
  }
  const { latitude, longitude } = coord;
  return callSummaryForecasts(latitude, longitude);
}

export function fetchForecasts(coord: Geopoint | null | undefined, day: string) {
  if (!coord) {
    return Promise.resolve(undefined);
  }

  const { latitude, longitude } = coord;
  const dayAsDayJs = plcDayjs(day);
  const from = dayAsDayJs.isSame(plcDayjs(), 'day') ? plcDayjs().startOf('hour') : dayAsDayJs.startOf('day');
  return callHourlyForecasts(latitude, longitude, from.toDate().toDateString());
}

export async function fetchEvents(stateOrProvinceCode?: string): Promise<Content[]> {
  try {
    const data = await searchContent({
      contentTypes: [ContentType.Event],
      limit: NB_HOME_EVENTS,
      searchType: SearchType.Prioritize,
      stateOrProvinceCodes: stateOrProvinceCode ? [stateOrProvinceCode] : undefined,
    });
    return data.contents;
  } catch (err: any) {
    console.error('Error while calling search for events :', err);
    return Constants.EMPTY_ARRAY;
  }
}

export async function fetchNewHeadlines(thematic: string | undefined) {
  let thematicCode = null;
  if (thematic !== undefined) {
    const thematicIndex = Object.values(thematicInUrl).findIndex((i) => i === thematic);
    thematicCode =
      thematicIndex >= 0
        ? transformUrlParamToEnum<ThematicCode>(Object.keys(thematicInUrl)[thematicIndex], ThematicCode)
        : null;
  }
  const data = await searchContent({
    contentTypes: [ContentType.Article],
    isHighlighted: thematic ? undefined : true,
    limit: NB_HOME_NEWS_HEADLINES,
    searchType: SearchType.Prioritize,
    thematics: thematicCode ? [thematicCode as ThematicCode] : undefined,
  });
  return data.contents;
}

export async function fetchLatestGuides(thematic: string) {
  const thematicIndex = Object.values(thematicInUrl).findIndex((i) => i === thematic);
  const thematicCode =
    thematicIndex >= 0
      ? transformUrlParamToEnum<ThematicCode>(Object.keys(thematicInUrl)[thematicIndex], ThematicCode)
      : null;
  const searchParams = {
    contentTypes: [ContentType.Guide],
    limit: NB_LAST_GUIDES,
    offset: 0,
    thematics: [thematicCode as ThematicCode],
  };
  return searchContent(searchParams).then((r) => filterGuides(r.contents));
}

export async function fetchCustomNews(
  isAuthenticated: boolean,
  stateOrProvinceCode: string | undefined,
  interests: InterestCode[],
  activities: ActivityCode[],
  isPrefsSet: boolean
) {
  if (!isAuthenticated || !isPrefsSet) {
    return Promise.resolve(Constants.EMPTY_ARRAY as Content[]);
  }
  try {
    const data = await getCustomJournalNews({
      activities,
      interests,
      stateOrProvinceCodes: stateOrProvinceCode ? [stateOrProvinceCode] : undefined,
    });
    return data.contents;
  } catch (err: any) {
    console.error('Error while calling search for custom news :', err);
    return Constants.EMPTY_ARRAY as Content[];
  }
}

export function fetchFreeField() {
  return FreeFieldApi.fetchForCode(FreeFieldCode.Home);
}

export async function fetchProductTypes(
  isMobile: boolean,
  userProductTypeLoading: boolean,
  userProductTypeError?: number,
  userProductTypes?: UserProductType[]
) {
  if (userProductTypeLoading) {
    return Promise.resolve([]);
  }
  if (userProductTypeError) {
    return Promise.reject(userProductTypeError);
  }
  if (userProductTypes && userProductTypes.length > 0) {
    return Promise.resolve(userProductTypes.slice(0, isMobile ? 1 : 2));
  }

  const defaultProductTypesIds = Constants.JOURNAL_DEFAULT_STOCK_PRODUCT_TYPES_ID.slice(0, isMobile ? 1 : 2);
  const types = await Promise.all(defaultProductTypesIds.map(getProductType));
  return types ? (types.filter((t) => t?.products && t?.products.length > 0) as UserProductType[]) : [];
}
