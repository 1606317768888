import React from 'react';
import { Text } from '@components/Text/Text.component';
import { usePlcRouter } from '@utils/customHooks';
import { urlEnum } from '@utils/url';
import classnames from 'classnames';
import styles from './RadarDesktopButton.module.scss';

const RadarDesktopButton = () => {
  const { push } = usePlcRouter();
  const className = classnames('meteo-button-1', styles.button);

  return (
    <button
      aria-label="meteo.navigate-to-radar"
      className={className}
      onClick={() => push(`${urlEnum.meteoRadar}`)}
      type="button"
    >
      <Text className={styles.span} i18nKey="meteo.navigate-to-radar" variant="h6" />
    </button>
  );
};

export default RadarDesktopButton;
