import React from 'react';
import { Text } from '@components/Text/Text.component';
import { useWeatherLink } from '@meteo/business/meteoUrl.utils';
import { usePlcRouter } from '@utils/customHooks';
import { urlEnum } from '@utils/url';
import classnames from 'classnames';
import styles from './MeteoButton.module.scss';

const MeteoButton = () => {
  const { push } = usePlcRouter();
  const className = classnames('meteo-button-2', styles.button);
  const linkProps = useWeatherLink(urlEnum.meteo);
  return (
    <button
      aria-label="meteo.go-to"
      className={className}
      onClick={() => push(linkProps.href, linkProps.as)}
      type="button"
    >
      <Text className={styles.span} i18nKey="meteo.go-to" variant="h6" />
    </button>
  );
};

export default MeteoButton;

