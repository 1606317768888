import React, { ComponentPropsWithRef, FC } from 'react';
import { PlcTextListLoader } from '@components/Loaders/PlcTextLoader.component';
import { Text } from '@components/Text/Text.component';
import { useTranslation } from '@locales/useTranslation.hook';
import { MeteoSingleMetricWhiteBack } from '@meteo/components/MeteoSingleMetricWhiteBack/MeteoSingleMetricWhiteBack';
import { MeteoSummaryMetric } from '@meteo/types';
import { formatNumberWithComma } from '@utils/number';
import classnames from 'classnames';
import styles from './MeteoMetricsLayout.module.scss';

export interface MeteoMetricsLayoutProps extends ComponentPropsWithRef<'div'> {
  rainLevel: number;
  minTemp: number;
  maxTemp: number;
  windSpeed: number;
  humidity: number;
  windDirection: string;
  windAngle: number;
  isLoading: boolean;
}

const MeteoMetricsLayout: FC<MeteoMetricsLayoutProps> = ({
  rainLevel,
  minTemp,
  maxTemp,
  windSpeed,
  humidity,
  windDirection,
  windAngle,
  className,
  isLoading,
  ...rest
}) => {
  const { t } = useTranslation(['meteo']);
  const rootClass = classnames('metrics-layout', 'width-100', 'inline-block', className);
  return (
    <div className={rootClass} {...rest}>
      {isLoading ? (
        <PlcTextListLoader
          className="plc-my-s"
          fixedWidth
          textProps={[{ variant: 'h5', width: 190 }]}
          uniqueKey="meteoMetricsLayout"
        />
      ) : (
        <Text i18nKey="meteo.today-forecast" tag="div" variant="h5" />
      )}
      <div className={styles.metricsBoard}>
        <MeteoSingleMetricWhiteBack
          className="plc-mx-xxs"
          isLoading={isLoading}
          type={MeteoSummaryMetric.RainLevel}
          value={t('meteo.rain-level', { value: formatNumberWithComma(rainLevel, 1) })}
        />
        <MeteoSingleMetricWhiteBack
          className="plc-mx-xxs"
          isLoading={isLoading}
          type={MeteoSummaryMetric.MinMaxTemperature}
          value={t('meteo.temp-min-max', {
            max: formatNumberWithComma(maxTemp),
            min: formatNumberWithComma(minTemp),
          })}
        />
        <MeteoSingleMetricWhiteBack
          className="plc-mx-xxs"
          isLoading={isLoading}
          type={MeteoSummaryMetric.WindSpeed}
          value={t('meteo.wind-speed', { value: formatNumberWithComma(windSpeed) })}
        />
        <MeteoSingleMetricWhiteBack
          className="plc-mx-xxs"
          isLoading={isLoading}
          type={MeteoSummaryMetric.Humidity}
          value={t('meteo.humidity', { value: formatNumberWithComma(humidity) })}
        />
        <MeteoSingleMetricWhiteBack
          className="plc-mx-xxs"
          isLoading={isLoading}
          options={{ angle: windAngle }}
          type={MeteoSummaryMetric.WindDirection}
          value={windDirection}
        />
      </div>
    </div>
  );
};

const MeteoMetricsLayoutLoader: FC = () => (
  <MeteoMetricsLayout
    humidity={0}
    isLoading={true}
    maxTemp={0}
    minTemp={0}
    rainLevel={0}
    windAngle={0}
    windDirection=""
    windSpeed={0}
  />
);

export { MeteoMetricsLayout, MeteoMetricsLayoutLoader };
