import Image from 'next/image';
import React, { FC } from 'react';
import { useWeatherLink } from '@meteo/business/meteoUrl.utils';
import { usePlcRouter } from '@utils/customHooks';
import { urlEnum } from '@utils/url';
import styles from './ImageMeteoSummary.module.scss';

interface Props {
  page: 'meteo' | 'home';
}

const ImageMeteoSummary: FC<Props> = ({ page }) => {
  const router = usePlcRouter();
  const radarLink = useWeatherLink(urlEnum.meteoRadar);

  switch (page) {
    case 'home':
      return (
        <div className={styles.imgContainer}>
          <Image
            alt="img-home"
            height={50}
            loading="eager"
            src="/img/journal/journal-meteo.jpg"
            unoptimized={true}
            width={500}
          />
        </div>
      );
    case 'meteo':
      return (
        <div className={styles.imgContainer}>
          <Image
            alt="radar"
            className="cursor-pointer"
            height={50}
            loading="eager"
            onClick={() => router.push(radarLink.href, radarLink.as)}
            src="/img/weather/map.png"
            unoptimized={true}
            width={500}
          />
        </div>
      );
    default:
      return null;
  }
};

export { ImageMeteoSummary };
