import { Content, GuideInfoEpisodeList } from 'pleinchamp-api-client';

export interface EpisodeInfos {
  episodesCount: number;
  currentEpisode: number;
  isSummary: boolean;
  nextEpisodeSlug?: string;
  previousEpisodeSlug?: string;
}

export function getEpisodeInfos(episodeList: GuideInfoEpisodeList[]): EpisodeInfos {
  const episodesCount = episodeList.length - 1;
  const currentEpisode = episodeList.findIndex(e => e.current);
  const isSummary = episodeList[0].current;
  const nextEpisodeSlug = episodeList[currentEpisode + 1] ? episodeList[currentEpisode + 1].slug : undefined;
  const previousEpisodeSlug = episodeList[currentEpisode - 1] ? episodeList[currentEpisode - 1].slug : undefined;
  return { currentEpisode, episodesCount, isSummary, nextEpisodeSlug, previousEpisodeSlug };
}

export function filterGuides(contents: Content[]) {
  return contents.filter(c => (c.guideDetail?.episodeList?.length || 0) > 0);
}
