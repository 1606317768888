import { motion } from 'framer-motion';
import React, { ComponentPropsWithRef, FC } from 'react';
import { IconSizes } from '@components/icons/icon.types';
import { HumidityPercentIcon } from '@components/icons/meteo/HumidityPercent.icon';
import { RainIcon } from '@components/icons/meteo/Rain.icon';
import { TempMinMax } from '@components/icons/meteo/TempMinMax.icon';
import { WindIcon } from '@components/icons/meteo/Wind.icon';
import { WindDirectionIcon } from '@components/icons/meteo/WindDirection.icon';
import { PlcContentLoader } from '@components/Loaders/PlcContentLoader.component';
import { PlcTextLoader } from '@components/Loaders/PlcTextLoader.component';
import { Text } from '@components/Text/Text.component';
import { useTranslation } from '@locales/useTranslation.hook';
import { TooltipProps } from '@material-ui/core';
import { MeteoSummaryMetric } from '@meteo/types';
import { Spacing } from '@styles/Spacing.style';
import { TextStyle } from '@styles/Text.style';
import { formatNumberWithComma } from '@utils/number';
import classnames from 'classnames';
import dynamic from 'next/dynamic';
import styles from './MeteoSingleMetricWhiteBack.module.scss';
import { PlcColor } from '@styles/colors';

const Tooltip = dynamic<TooltipProps>(() => import('@material-ui/core/').then((module) => module.Tooltip));

interface Props extends ComponentPropsWithRef<'div'> {
  type: MeteoSummaryMetric;
  value: string;
  subValue?: string;
  isLoading?: boolean;
  options?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
    angle?: number;
  };
}

function buildIconFromType(type: MeteoSummaryMetric, variant: IconSizes, iconSize: number, options: Props['options']) {
  switch (type) {
    case MeteoSummaryMetric.RainLevel: {
      return <RainIcon fillColor={PlcColor.BLACK} height={iconSize} variant={variant} width={iconSize} />;
    }
    case MeteoSummaryMetric.MinMaxTemperature: {
      return <TempMinMax fillColor={PlcColor.BLACK} height={iconSize} variant={variant} width={iconSize} />;
    }
    case MeteoSummaryMetric.WindSpeed: {
      return <WindIcon fillColor={PlcColor.BLACK} height={iconSize} variant={variant} width={iconSize} />;
    }
    case MeteoSummaryMetric.Humidity: {
      return <HumidityPercentIcon fillColor={PlcColor.BLACK} height={iconSize} variant={variant} width={iconSize} />;
    }
    case MeteoSummaryMetric.WindDirection: {
      const angle = options && options.angle ? options.angle : 0;
      const animate = {
        rotate: `${360 * 2 - 180 + angle}deg`,
      };
      return (
        <motion.div animate={animate} transition={{ delay: 1, duration: 0.7 }}>
          <WindDirectionIcon fillColor={PlcColor.BLACK} height={iconSize} width={iconSize} />
        </motion.div>
      );
    }
    default: {
      const switchComplete: never = type;
      return switchComplete;
    }
  }
}

function formatMeteoMetric(value: string, metricType: MeteoSummaryMetric) {
  if (metricType === MeteoSummaryMetric.RainLevel) {
    return formatNumberWithComma(value, 1);
  }
  return value;
}

const MeteoSingleMetricsWhiteBackLoader: FC<{ uniqueKey: string }> = ({ uniqueKey }) => {
  const iconSize = 60;
  const textVariant = 'h5';
  const iconRadius = iconSize / 2 - 2;
  const textWidth = iconSize;
  const width = textWidth + 2 * Spacing.xxs;
  const height = iconSize + TextStyle[textVariant].lineHeight;
  return (
    <PlcContentLoader fixedWidth height={height} uniqueKey={uniqueKey} width={width}>
      <circle cx={width / 2} cy={iconSize / 2} r={iconRadius} />
      <PlcTextLoader variant={textVariant} width={textWidth} x={(width - textWidth) / 2} y={iconSize} />
    </PlcContentLoader>
  );
};

const MeteoSingleMetricWhiteBack: FC<Props> = ({ type, value, subValue, className, isLoading, options, ...rest }) => {
  const rootClass = classnames(styles.meteoSingleMetric, className);
  const { t } = useTranslation(['meteo']);
  const iconVariant: IconSizes = IconSizes.big;
  const iconSize = 40;
  const textVariant = 'h6';

  if (/undefined/.test(value)) {
    return null;
  }

  return (
    <div className={rootClass} {...rest}>
      {!isLoading ? (
        <>
          <Tooltip enterDelay={500} placement="top" title={t(`meteo.metric.${type}`) as string}>
            <div>{buildIconFromType(type, iconVariant, iconSize, options)}</div>
          </Tooltip>
          <Text alignment="center" tag="span" variant={textVariant}>
            {formatMeteoMetric(value, type)}
          </Text>
          {subValue && !/undefined/.test(subValue) && (
            <Text alignment="center" className="metric-sub-label" flavour="grey" tag="span" variant="h6">
              {subValue}
            </Text>
          )}
        </>
      ) : (
        <MeteoSingleMetricsWhiteBackLoader uniqueKey={`meteo-${type}-metric`} />
      )}
    </div>
  );
};

export { MeteoSingleMetricWhiteBack };
