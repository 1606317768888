import { LocalMeteoSummaryLoader, LocalMeteoSummaryProps } from '../LocalMeteoSummary/LocalMeteoSummary.component';
import { SummaryForecast } from 'pleinchamp-api-client';
import React, { FC } from 'react';
import { usePlcApiCall } from '@api/business/api.hook';
import MeteoButton from '@components/buttons/MeteoButton/MeteoButton.component';
import RadarDesktopButton from '@components/buttons/RadarDesktopButton/RadarDesktopButton.component';
import { ImageMeteoSummary } from '@components/ImageMeteoSummaryComponent/ImageMeteoSummary.component';
import { PlcMessageWithRetryProps } from '@components/PlcMessage/PlcMessageWithRetry.component';
import { useLocation } from '@meteo/business/LocationSelector.hook';
import {
  MeteoCityAutocompleteLoader,
  MeteoCityAutocompleteProps,
} from '@meteo/components/MeteoCityAutocomplete/MeteoCityAutocomplete.component';
import { MeteoHomeSummaryNoCityProps } from '@meteo/components/MeteoHomeSummaryNoCity/MeteoHomeSummaryNoCity.component';
import {
  MeteoMetricsLayoutLoader,
  MeteoMetricsLayoutProps,
} from '@meteo/components/MeteoMetricsLayout/MeteoMetricsLayout.component';
import { City, StandardCity } from '@meteo/types';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import { usePlcRouter } from '@utils/customHooks';
import { fetchSummaryForecasts } from '@utils/fetch';
import { isServer } from '@utils/server';
import { urlEnum } from '@utils/url';
import classnames from 'classnames';
import dynamic from 'next/dynamic';
import styles from './MeteoHomeSummary.module.scss';

const MeteoCityAutocomplete = dynamic<MeteoCityAutocompleteProps>(
  () =>
    import('@meteo/components/MeteoCityAutocomplete/MeteoCityAutocomplete.component').then(
      (mod) => mod.MeteoCityAutocomplete
    ),
  {
    loading: () => <MeteoCityAutocompleteLoader />,
    ssr: false,
  }
);

const MeteoHomeSummaryNoCity = dynamic<MeteoHomeSummaryNoCityProps>(() =>
  import('@meteo/components/MeteoHomeSummaryNoCity/MeteoHomeSummaryNoCity.component').then(
    (mod) => mod.MeteoHomeSummaryNoCity
  )
);

const LocalMeteoSummary = dynamic<LocalMeteoSummaryProps>(
  () => import('@meteo/components/LocalMeteoSummary/LocalMeteoSummary.component').then((mod) => mod.LocalMeteoSummary),
  {
    loading: () => <LocalMeteoSummaryLoader uniqueKey="localMeteoSummary" />,
    ssr: false,
  }
);

const MeteoMetricsLayout = dynamic<MeteoMetricsLayoutProps>(
  () =>
    import('@meteo/components/MeteoMetricsLayout/MeteoMetricsLayout.component').then((mod) => mod.MeteoMetricsLayout),
  {
    loading: () => <MeteoMetricsLayoutLoader />,
    ssr: false,
  }
);

const PlcMessageWithRetry = dynamic<PlcMessageWithRetryProps>(
  () => import('@components/PlcMessage/PlcMessageWithRetry.component').then((mod) => mod.PlcMessageWithRetry),
  { ssr: false }
);

interface MeteoHomeSummaryProps {
  initialData?: SummaryForecast | undefined;
  setCity: ((data: City) => void) | undefined;
  setForecasts?: ((data: SummaryForecast) => void) | undefined;
  reversedCity: City | StandardCity | undefined;
}

const MeteoHomeSummary: FC<MeteoHomeSummaryProps> = ({ initialData, setForecasts, setCity, reversedCity }) => {
  const isMobile = useIsBreakpointDown(Breakpoint.m);

  const { coordinates, geolocationError } = useLocation(reversedCity);

  const {
    data: forecasts,
    error: forecastError,
    isValidating: loading,
    retryCallback: retryForecast,
  } = usePlcApiCall(fetchSummaryForecasts, [coordinates], { initialData });

  if (forecasts && forecasts !== initialData && setForecasts) {
    setForecasts(forecasts);
  }

  const isLoadingOrServer = loading || isServer();
  const route = usePlcRouter();
  const isHomePage = route.asPath === urlEnum.home;

  return (
    <div className={styles['meteo-home-summary']}>
      <div className={styles['meteo-home-summary-wrapper']}>
        <div className={classnames(styles['meteo-home-summary-search'], 'plc-pt-xs')}>
          {!!reversedCity && (
            <MeteoCityAutocomplete
              current={reversedCity}
              inputId="meteo-city-autocomplete-input-with-city"
              isLeftIconStyle={true}
              onSelect={(c) => (c && setCity ? setCity(c) : undefined)}
              useLastCity={false}
              withConnectSuggestion
            />
          )}
        </div>
        <div className={styles['meteo-home-summary-main']}>
          {!reversedCity && <MeteoHomeSummaryNoCity setCity={setCity} />}
          {forecasts && (
            <div>
              <LocalMeteoSummary
                dateTime={forecasts.weatherCode.date}
                sunrise={forecasts.sunrise.value}
                sunset={forecasts.sunset.value}
                temperature={forecasts.airTemperature.value}
                weatherCode={forecasts.weatherCode.value}
              />
              <MeteoMetricsLayout
                humidity={forecasts.relativeHumidity.value}
                isLoading={Boolean(loading)}
                maxTemp={forecasts.maxAirTemperature.value}
                minTemp={forecasts.minAirTemperature.value}
                rainLevel={forecasts.precipitationAmount.value}
                windAngle={forecasts.windDirection.value.degree}
                windDirection={forecasts.windDirection.value.cardinal}
                windSpeed={forecasts.windSpeedAt2m.value}
              />
              <div>
                {isMobile && (
                  <>
                    {isHomePage && <MeteoButton />}
                    <RadarDesktopButton />
                  </>
                )}
              </div>
            </div>
          )}
          {!isLoadingOrServer && reversedCity && (forecastError || geolocationError || !forecasts) && (
            <div>
              <PlcMessageWithRetry isLoading={loading} retryCallback={retryForecast} />
            </div>
          )}
        </div>
        {!isMobile && <ImageMeteoSummary page={isHomePage ? 'home' : 'meteo'} />}
        <div>
          {!isMobile && (
            <>
              {isHomePage && <MeteoButton />}
              <RadarDesktopButton />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { MeteoHomeSummary };
