import React, { ComponentPropsWithRef, FC, useMemo } from 'react';
import { IconSizes } from '@components/icons/icon.types';
import { WeatherCodeIcon } from '@components/icons/meteo/WeatherCode';
import { PlcTextListLoader } from '@components/Loaders/PlcTextLoader.component';
import { Text } from '@components/Text/Text.component';
import { useTranslation } from '@locales/useTranslation.hook';
import { isItNight } from '@meteo/business/meteo.utils';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import { formatDateToDateTime, formatDateToTime, plcDayjs } from '@utils/date';
import { formatNumberWithComma } from '@utils/number';
import classnames from 'classnames';

import styles from './LocalMeteoSummary.module.scss';
import { mapApiWeatherCodeToWeatherCode } from "@meteo/business/meteo.weatherCodeMapping";

export interface LocalMeteoSummaryProps extends ComponentPropsWithRef<'header'> {
  weatherCode: number;
  dateTime: string;
  temperature: number;
  sunrise: string;
  sunset: string;
}

const LocalMeteoSummary: FC<LocalMeteoSummaryProps> = ({
  weatherCode,
  dateTime,
  temperature,
  sunrise,
  sunset,
  className,
  ...rest
}) => {
  const { t } = useTranslation(['common']);
  const rootClass = classnames(styles['local-meteo-summary'], className);
  const isMobileOrTablet = useIsBreakpointDown(Breakpoint.l);
  const updateString = useMemo(() => {
    const dateTimeWithOffset = plcDayjs(dateTime).subtract(1, 'hour');

    return dateTimeWithOffset.isSame(plcDayjs(), 'day')
      ? t('common.last-update-time', { time: formatDateToTime(dateTimeWithOffset) })
      : formatDateToDateTime(dateTimeWithOffset);
  }, [dateTime]);
  const night = isItNight(new Date(sunrise), new Date(sunset));

  return (
    <header className={rootClass} {...rest}>
      <div className={styles['local-meteo-weather-icon-wrapper']}>
        <WeatherCodeIcon night={night} variant={IconSizes.xl} weatherCode={weatherCode} />
      </div>
      <div className={classnames(styles['local-meteo-weather-and-date'], 'plc-mb-up-l-0', 'plc-mr-m')}>
        <Text
          className={classnames(styles['local-meteo-weather-label'], 'plc-mb-xs')}
          i18nKey={`meteo.weather-code.${mapApiWeatherCodeToWeatherCode(weatherCode)}`}
          tag="div"
          variant={isMobileOrTablet ? 'h3' : 'h2'}
        />
        <Text flavour="grey" tag="div" variant={isMobileOrTablet ? 'h6' : 'h5'}>
          {updateString}
        </Text>
      </div>
      <Text
        className={styles['local-meteo-temperature']}
        i18nKey="meteo.temperature"
        i18nOptions={{ value: formatNumberWithComma(temperature, 1) }}
        tag="div"
        variant={isMobileOrTablet ? 'h2' : 'h1'}
      />
    </header>
  );
};

const LocalMeteoSummaryLoader: FC<{ uniqueKey: string }> = ({ uniqueKey })=> {
  const isMobileOrTablet = useIsBreakpointDown(Breakpoint.l);
  return (
    <header className={styles['local-meteo-summary']}>
      <div className={classnames(styles['local-meteo-weather-and-date'], 'plc-mb-l', 'plc-mb-up-l-0', 'plc-mr-m')}>
        <PlcTextListLoader
          fixedWidth
          textProps={[
            { numberOfLine: 2, variant: isMobileOrTablet ? 'h3' : 'h2', width: 300 },
            { variant: isMobileOrTablet ? 'h6' : 'h5', width: 130 },
          ]}
          uniqueKey={uniqueKey}
        />
      </div>
    </header>
  );
};

export { LocalMeteoSummary, LocalMeteoSummaryLoader };
